
import pulseAnimimationJson from '~/static/animations/pulse.json'

export default {
    data(){
        return {
            pulseAnimation: {
                animationData: pulseAnimimationJson,
                animationSpeed: 1,
                autoplay: true,
                renderer: 'svg',
                loop: true
            },
        }
    },
}
